<template>
  <ul class="c-portfolio-intro-list">
    <template v-for="portfolio in portfolios">
      <!-- Active portfolios -->
      <router-link
        v-if="isActive(portfolio)"
        :key="portfolio.id"
        :to="{ name: 'portfolioBuildings', params: { portfolio_id: portfolio.id } }"
      >
        <PortfolioIntroListItem :portfolio="portfolio" />
      </router-link>

      <!-- Inactive portfolios -->
      <div v-else :key="portfolio.id" class="inactive">
        <PortfolioIntroListItem :portfolio="portfolio" />
      </div>
    </template>
  </ul>
</template>

<script>
import PortfolioIntroListItem from './PortfolioIntroListItem.vue'

export default {
  components: {
    PortfolioIntroListItem,
  },

  props: {
    portfolios: {
      type: Array,
      required: true,
    },
  },

  methods: {
    isActive(portfolio) {
      if (portfolio.inactive_after === null) {
        return true
      } else {
        const inactive = new Date(Date.parse(portfolio.inactive_after))
        inactive.setHours(23, 59, 59, 999)
        return new Date() <= inactive
      }
    },
  },
}
</script>

<style lang="scss">
.c-portfolio-intro-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: var(--spacing-m);

  & > * {
    height: min-content;
    border-radius: var(--box-radius);
  }

  & li {
    & > .item {
      border-radius: var(--box-radius);
      border: var(--box-border);
      transition: box-shadow 0.2s ease-in-out;

      &:hover {
        box-shadow: 0px 8px 16px -2px #eee;
      }
    }
  }

  & .inactive {
    opacity: 0.5;
  }
}
</style>
