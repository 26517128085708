<i18n>
  {
    "de": {
      "buildingsCount": "Liegenschaft | Liegenschaften",
      "simulationPending": "{n} in Berechnung"
    }
  }
</i18n>

<template>
  <li
    class="c-portfolio-intro-list-item"
    @mouseenter="showSummaryInfoBox = true"
    @mouseleave="showSummaryInfoBox = false"
  >
    <div class="item">
      <!-- Header: Portfolio name -->
      <div class="header">
        <div class="portfolio-id">{{ portfolio.name }}</div>
      </div>

      <!-- Main: Portfolio summary -->
      <div class="main">
        <div class="portfolio-description">
          <span>{{ portfolio.summary?.total }} {{ $tc('buildingsCount', portfolio.summary?.total) }}</span>
          <transition name="fade">
            <SummaryInfoBox v-if="showSummaryInfoBox" :summaryData="portfolio.summary" />
          </transition>
        </div>
      </div>

      <!-- Footer: Simulation queue -->
      <div v-if="buildingSimulationQueue(portfolio.id).length > 0" class="footer">
        <div class="portfolio-intro-list-item-queue">
          <BuildingQueuedIndicator />
          <span>{{ $tc('simulationPending', this.buildingSimulationQueue(portfolio.id).length) }}</span>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

import BuildingQueuedIndicator from '@/components/shared/BuildingQueuedIndicator.vue'
import SummaryInfoBox from '../portfolio/SummaryInfoBox.vue'

export default {
  components: {
    BuildingQueuedIndicator,
    SummaryInfoBox,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showSummaryInfoBox: false,
    }
  },

  computed: {
    ...mapGetters({
      buildingSimulationQueue: 'queue/buildingSimulationQueue',
    }),
  },
}
</script>

<style lang="scss">
.c-portfolio-intro-list-item {
  & .header,
  & .main,
  & .footer {
    padding: var(--box-padding);
  }

  & .header {
    border-bottom: var(--box-border);
    min-height: 40px;
  }

  & .main {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
  }

  & .footer {
    align-self: end;
    border-top: var(--box-border);
    font-weight: 400;

    & ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: var(--font-xs);
    }

    & ul ~ ul {
      margin-top: var(--spacing-xs);
    }
  }

  & .item {
    display: grid;
    grid-template-rows: auto auto 1fr;
  }

  & .portfolio-id {
    font-weight: 700;
  }

  & .portfolio-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xxs);
    width: 100%;
    font-size: var(--font-s);
    font-weight: 400;

    & span {
      line-height: 16px;
    }
  }

  & .portfolio-intro-list-item-queue {
    display: flex;
    gap: 5px;
    align-items: baseline;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
